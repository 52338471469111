/**
 * App Config File
 */
export default {
  appLogo: '/img/theme/app_icn_sn.png', // App Logo,
  darkLogo: '/img/theme/app_icn_sn.png', // dark logo
  brand: 'Santiago Nieto', // Brand Name
  copyrightText: 'Pixzelle © 2020 All Rights Reserved.', // Copyright Text
  apiUrl: 'https://snqueretaro.com/api/', // https://conmarcelosi.globalcybersec.com
  // apiUrl: 'https://manolito-dev.globalcybersec.com/api/', // https://manolito-dev.globalcybersec.com/api/
  googleMapsKey: 'AIzaSyCQOh9oThS-mTlQMh9aLsYkzpaJ2Bp-fiA',
  urlIcon: '/img/themes/marker.png',
  testResultFieldName: 'Terminado'
}
